@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.g-card {
	margin: 20px;
}

.g-card-image {
	border-radius: 10px;
	width: 300px;
	height: 480px;
	box-shadow: 0px 0px 3px 1px #ccc;
}

.g-card-info {
	margin-top: 10px;
	min-height: 100px;
}

.g-card-title {
	font-size: 24px;
	margin: 0px;
}

.g-card-sub-title {
	font-size: 16px;
	margin: 0px;
}

.success-msg {
	color: #57a773;
	padding: 10px 15px;
}

.err-msg {
	color: #ee6352;
	padding: 10px 15px;
}

.checkmark__circle {
	margin-left: 15px;
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #57a773;
	fill: none;
	-webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	margin-left: 15px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	box-shadow: inset 0px 0px 0px #57a773;
	-webkit-animation: fill 0.4s ease-in-out 0.4s forwards,
		scale 0.3s ease-in-out 0.9s both;
	        animation: fill 0.4s ease-in-out 0.4s forwards,
		scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
	margin-left: 15px;
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	-webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@-webkit-keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}
@-webkit-keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #57a773;
	}
}
@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #57a773;
	}
}

#footer-bar {
	position: fixed;
	left: 0px;
	bottom: 0px;
	height: 3rem;
	width: 100%;
	background: white;
	z-index: 9;
}

#navbar {
	background: white;
}

#body-tag {
	padding-bottom: 5px;
}

#contact-form-element {
	padding-bottom: 13.23%;
}

#font-icon {
	color: rgba(255, 255 255, 65);
	text-shadow: 1px 1px 1px #cccc;
	font-size: 1.75em;
	visibility: visible;
}

#icon-b {
	visibility: hidden;
	display: flex;
	justify-content: space-around;
	margin-left: 1rem;
}

.loader {
	color: black;
	font-size: 90px;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	margin: 72px auto;
	position: center;
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
			-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
			-0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
			-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
			-0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
			-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
			-0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
			0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.example_e {
	border: none;
	background: #404040;
	color: #ffffff !important;
	font-weight: 100;
	padding: 1rem;
	text-transform: uppercase;
	border-radius: 6px;
	display: inline-block;
	transition: all 0.3s ease 0s;
	align-self: center;
	margin-bottom: 1rem;
	z-index: 9;
}

.example_e:hover {
	color: #404040 !important;
	font-weight: 700 !important;
	letter-spacing: 3px;
	background: none;
	box-shadow: inset 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
	transition: all 0.3s ease 0s;
}

#font-icon-skills {
	font-size: 2.5rem;
	margin-left: 3rem;
	margin-top: 1rem;
}

.hitsloader,
.hitsloader:before,
.hitsloader:after {
	border-radius: 50%;
	width: 0.5rem;
	height: 0.5rem;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
}
.hitsloader {
	color: #000000;
	font-size: 1rem;
	margin: 0 auto;
	position: relative;
	text-indent: -9999rem;
	transform: translateZ(0);
	-webkit-animation-delay: -0.05s;
	animation-delay: -0.05s;
}
.hitsloader:before,
.hitsloader:after {
	content: "";
	position: absolute;
	top: 0;
}
.hitsloader:before {
	left: -1rem;
	-webkit-animation-delay: -0.05s;
	animation-delay: -0.05s;
}
.hitsloader:after {
	left: 1rem;
}
@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 1rem 0 -0.5rem;
	}
	40% {
		box-shadow: 0 1rem 0 0;
	}
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 1rem 0 -0.5rem;
	}
	40% {
		box-shadow: 0 1rem 0 0;
	}
}

* {
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
}

#home-button {
	display: flex;
	align-items: center;
	justify-content: left;
	height: 15%;
	background: transparent;
}

.effect1 {
	color: #222;
	text-decoration: none;
	font-family: sans-serif;
	font-size: 3rem;
	position: relative;
	padding: 10px 50px 10px 20px;

	transition: all 0.3s;

	transform: scale(1); /*change scale(3) to scale(1)*/
}

.effect1 .bg {
	background: #222;
	width: 30px;
	height: 2px;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -1px;
	z-index: -1;

	transition: all 0.3s;
}

.effect1:hover {
	padding-right: 20px;
	color: #fff;
}

.effect1:hover .bg {
	height: 100%;
	width: 100%;
	transform: translate(0, -50%);
}

.effect1 .bg:before,
.effect1 .bg:after {
	content: "";
	height: 2px;
	width: 10px;
	background: #222;
	position: absolute;
	right: -2px;
	transition: all 0.3s;
}

.effect1 .bg:before {
	bottom: 3px;
	transform: rotate(45deg);
}
.effect1 .bg:after {
	top: 3px;
	transform: rotate(-45deg);
}

.effect1:hover .bg:before,
.effect1:hover .bg:after {
	right: 0;
}

.effect1:hover .bg:before {
	bottom: 6px;
}

.effect1:hover .bg:after {
	top: 6px;
}

#first {
	width: 100%;
	position: absolute;
}
#second {
	width: 100%;
	position: relative;
	z-index: -2;
	height: 55rem;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* About Page */
.about-page-right {
	font-size: small;
}

img {
	border-radius: 50%;
}

